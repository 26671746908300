<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="buttonbc">
      <!-- <el-button @click="() => { $router.go(0); }" size="small" type="primary" >刷新</el-button> -->
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <div class="cont" v-if="orderDetail">
      <br />
      <h4>基本信息</h4>
      <table class="table-cont" v-if="shopInfo.shop_type !== 2">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：
              <span class="detail-span1">{{ trade_status(orderDetail.trade_status) }}</span>
            </td>
          </tr>
          <!-- 下单人信息 -->
          <tr>
            <td>下单人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人姓名：{{ orderDetail.member_name }}</td>
            <td>下单人手机号：{{ allData.mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人所属分组：{{ orderDetail.group_name }}</td>
            <td>下单人账号：{{ orderDetail.login_account || '' }}</td>
          </tr>
          <!-- 订单信息 -->
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>用户下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>用户支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <!-- 站点-->
          <tr class="detail-tr1" v-if="shopInfo.shop_type === 6">
            <td>
              订单总价：
              <!-- {{orderDetail.shop_total_price | unitPrice("¥")}} -->
              {{ orderDetail.account_pay_money + orderDetail.weixin_pay_money + orderDetail.discount_card_coupon_price +
                orderDetail.discount_card_voucher_price | unitPrice("¥") }}
              <span>(商品：{{ orderDetail.shop_goods_price | unitPrice("¥") }} + 站点运费：{{ orderDetail.shop_freight_price |
                unitPrice("¥") }})</span>
            </td>
            <td>用户支付方式：
              <span v-if="orderDetail.trade_kind == 3">商品售后换新</span>
              <span v-else-if="orderDetail.trade_kind == 2">售后更换商品</span>
              <span v-else-if="orderDetail.trade_kind == 4">后台补单</span>
              <span v-else>{{ orderDetail.payment_method_name }}</span>
            </td>
          </tr>
          <tr class="detail-tr1" v-if="shopInfo.shop_type === 6">
            <td v-if="!(!this.MixinIsFormEnterprise && this.ispetroChina)">
              商城支付金额：{{ orderDetail.enterprise_total_price | unitPrice("¥") }}
              <span>(商品：{{ orderDetail.enterprise_goods_price | unitPrice("¥") }} + 企业运费：{{
                orderDetail.enterprise_freight_price | unitPrice("¥") }})</span>
            </td>
            <td v-if="!(!this.MixinIsFormEnterprise && this.ispetroChina)">商城支付时间：{{ orderDetail.create_time | unixToDate
            }}</td>
          </tr>
          <!-- 收货人信息 -->
          <tr>
            <td>收货人信息</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.consignee_name }}</td>
            <td>收货人手机号：{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：
              {{ orderDetail.consignee_province }}{{ orderDetail.consignee_city }}{{
                orderDetail.consignee_county
              }}{{ orderDetail.consignee_town ? orderDetail.consignee_town : "" }}{{ orderDetail.consignee_address }}
            </td>
          </tr>
          <!-- <tr v-if="sourceData === '套餐卡商城'">
            <td>备注信息</td>
          </tr> -->
          <!-- <tr v-if="sourceData === '套餐卡商城'" class="detail-tr1" style="display:inline-block; max-width: 30vw">
            <td v-if="refund_detail.remark">
              {{ refund_detail.remark }}
            </td>
            <td v-else>无</td>
          </tr> -->
          <tr v-if="self_type === '1' && shopInfo.shop_type === 3">
            <td>订单来源信息</td>
          </tr>
          <tr class="detail-tr1" v-if="self_type === '1' && shopInfo.shop_type === 3">
            <td>来源应用：{{ sourceData }}</td>
            <td>商城名称：{{ refund_detail.shop_name }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table-cont" v-else>
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：
              <span class="detail-span1">{{ trade_status(orderDetail.trade_status) }}</span>
            </td>
          </tr>
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1" v-if="shopInfo.shop_id !== 66">
            <td>订单总价：¥{{ orderDetail.order_total_price | unitPrice("¥") }}</td>
            <td>平台支付金额：{{ orderDetail.order_total_price | unitPrice("¥") }}</td>
          </tr>
          <tr class="detail-tr1" v-if="shopInfo.shop_id === 66">
            <td>
              平台支付金额：{{ orderDetail.order_total_price | unitPrice("¥") }}
              <span>
                (商品:{{ orderDetail.order_total_price | unitPrice("¥") }} + 京东运费: {{ orderDetail.order_total_price |
                  unitPrice("¥") }})
              </span>
            </td>
          </tr>
          <tr class="detail-tr1">
            <td>平台支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <tr>
            <td>收货人信息</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.consignee_name }}</td>
            <td>收货人手机号：{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：
              {{ orderDetail.consignee_province }}{{ orderDetail.consignee_city }}{{
                orderDetail.consignee_county
              }}{{ orderDetail.consignee_town ? orderDetail.consignee_town : "" }}{{ orderDetail.consignee_address }}
            </td>
          </tr>
          <tr v-if="allData.source === '套餐卡商城'">
            <td>备注信息</td>
          </tr>
          <tr v-if="allData.source === '套餐卡商城'" class="detail-tr1" style="display:inline-block; max-width: 30vw">
            <td v-if="allData.refund.remark">
              {{ allData.refund.remark }}
            </td>
            <td v-else>无</td>
          </tr>
          <!-- 京东供应商-->
          <template v-if="shopInfo.shop_id === 66">
            <tr>
              <td>订单来源：</td>
            </tr>
            <tr class="detail-tr1">
              <td>企业名称：{{ refund_detail.enterprise_name }}</td>
              <td>商城名称：{{ refund_detail.shop_name }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <br />
      <h4>商品信息</h4>
      <template>
        <el-table :data="productList" :header-cell-style="{ background: '#cccccc' }" :expand-row-keys="rowKeysList"
          row-key="sku_sn">
          <el-table-column prop="goods_image" label="商品详情" width="300">
            <template slot-scope="scope">
              <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
                <img :src="scope.row.goods_image" class="goods-image" />
                <el-tooltip class="item" effect="light" :content="scope.row.goods_name" placement="top">
                  <el-button style="max-width: 19vw; overflow: hidden; text-overflow: ellipsis;" class="shop-name"
                    type="text">{{ scope.row.goods_name }}
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="return_num" label="数量"></el-table-column>
          <el-table-column prop="price" label="会员价" v-if="MixinIsFormEnterprise">
            <template slot-scope="scope">{{
              scope.row.price | unitPrice('￥')
            }}
            </template>
          </el-table-column>
          <el-table-column prop="shop_price" label="销售价" v-if="shopInfo.shop_type !== 2">
            <template slot-scope="scope">{{ scope.row.shop_price | unitPrice('￥') }}</template>
          </el-table-column>
          <el-table-column prop="shop_supplier_price" label="销售价" v-else>
            <template slot-scope="scope">{{ scope.row.shop_supplier_price | unitPrice('￥') }}</template>
          </el-table-column>
          <el-table-column prop="enterprise_price" label="分销价" v-if="!(!this.MixinIsFormEnterprise && this.ispetroChina)">
            <template slot-scope="scope">
              {{ scope.row.shop_enterprise_price | unitPrice('￥') }}
            </template>
          </el-table-column>
          <el-table-column prop="shop_total_price" label="物流状态">
            <template slot-scope="scope">
              {{ !state ? '未发货' : (state === 2 ? '已发货' : '已收货') }}
            </template>
          </el-table-column>
          <el-table-column label="维权状态">
            <template slot-scope="scope">
              <!-- {{fService_status(scope.row.service_status)}} -->
              {{ refund_detail.refund_status_text }}
            </template>
          </el-table-column>
          <!-- 站点 审核 -->
          <el-table-column label="操作" v-if="shopInfo.shop_type === 6">
            <el-button v-if="refund_detail.refund_status === 'APPLY' && refund_detail.refuse_shop_status === 0"
              @click="examineRefund(refund_detail.sn)">审核
            </el-button>
          </el-table-column>
          <!-- 供应商 -->
          <el-table-column label="操作" v-else-if="shopInfo.shop_type === 2">
            <el-button v-if="refund_detail.refund_status === 'APPLY'" @click="examineRefund(refund_detail.sn)">审核
            </el-button>
            <el-button v-else-if="refund_detail.after_sale_operate_allowable['allow_stock_in']"
              @click="handleWareHousing(refund_detail.sn)">确认收货
            </el-button>
            <el-button v-else-if="refund_detail.after_sale_operate_allowable.allow_seller_ship"
              @click="deliverGoods(refund_detail.order_sn, refund_detail.sn)">重新发货
            </el-button>
          </el-table-column>
          <!-- 企业 -->
          <el-table-column label="操作" v-else-if="shopInfo.shop_type === 3">

          </el-table-column>
        </el-table>
      </template>
      <br />
      <h4>包裹信息</h4>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="包裹1" v-if="expressInfo.length !== 0" name="1">
          <p style="background-color: #6666; height: 30px; line-height: 30px;">
            <span>快递公司: {{ expressInfo[0].logiName }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ expressInfo[0].deliveryNo }}</span>
          </p>
          <!-- 普通物流 -->
          <div class="logistics-info" v-if="expressInfo[0]['code']">
            <p v-if="!expressInfo[0].data" style="display:block; line-height: 20px;">{{ expressInfo[0].message }}</p>
            <ul v-else>
              <li v-for="(ship, index) in expressInfo[0].data.orderTrack" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.content }}</p>
                  <p class="ship-msg-time">{{ ship.msgTime }}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 京东物流 -->
          <div class="logistics-info" v-else>
            <p v-if="!expressInfo[0].data" style="display:block; line-height: 20px;">{{ expressInfo[0].message }}</p>
            <ul v-else>
              <li v-for="(ship, index) in expressInfo[0].data[0].express.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="售后信息" name="2">
          <table>
            <tr>
              <td>售后单号：</td>
              <td>{{ refund_detail.sn }}</td>
            </tr>
            <tr>
              <td>关联订单：</td>
              <td>{{ refund_detail.trade_sn }}</td>
            </tr>
            <tr>
              <td>申请时间：</td>
              <td>{{ refund_detail.create_time | unixToDate }}</td>
            </tr>
            <tr>
              <td>售后状态：</td>
              <td>{{ refund_detail.refund_status_text }}</td>
            </tr>
            <tr>
              <td>服务类型：</td>
              <td>{{ refund_detail.refuse_type_text }}</td>
            </tr>
            <tr>
              <td>申请原因：</td>
              <td>{{ refund_detail.refund_reason }}</td>
            </tr>
            <tr>
              <td>问题描述：</td>
              <td>{{ refund_detail.customer_remark ? refund_detail.customer_remark : '' }}</td>
            </tr>
            <tr v-if="imgList.length !== 0">
              <td style="padding-bottom: 55px;">图片凭证：</td>
              <td>
                <div style="display: flex;">
                  <img :src="i" v-for="(i, index) in imgList" :key="index" style="width: 80px; height: 80px;" />
                </div>
              </td>
            </tr>
            <tr v-else>
              <td>图片凭证：</td>
              <td>无</td>
            </tr>
            <tr>
              <td>退货方式：</td>
              <td>退回余额</td>
            </tr>
            <tr v-if="refund_detail.refund_status !== 'APPLY' && refund_detail.refund_status !== 'REFUSE'">
              <td>是否已寄回：</td>
              <td v-if="refundExpressInfo">是</td>
              <td v-else>否</td>
            </tr>
            <tr>
              <td>退款金额：</td>
              <td v-if="shopInfo.shop_type === 6">{{ refund_detail.refund_shop_price }}</td>
              <td v-if="shopInfo.shop_type === 2">{{ refund_detail.refund_supplier_price }}</td>
              <td v-if="shopInfo.shop_type === 3">{{ refund_detail.refund_enterprise_price }}</td>
            </tr>
            <tr v-if="refund_detail.refund_reason === '线下售后'">
              <td>物流信息</td>
              <td>
                当前商品会退回到京东指定的退货地址。详细物流信息请通过您邮寄时的物流单号进行查询。
              </td>
            </tr>
            <tr v-if="refund_detail.refund_reason === '京东取消订单'">
              <td>物流信息</td>
              <td>
                京东取消订单。
              </td>
            </tr>
          </table>
        </el-tab-pane>
      </el-tabs>
      <br />
      <div style="display: flex;" v-if="shopInfo.shop_type === 6 && refundExpressInfo">
        <div style="margin-right: 20px;">
          <h4>退/换货商品物流信息：</h4>
          <p class="new_logistics">
            <span>快递公司: {{ refundExpressInfo.name }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ refundExpressInfo.courier_num }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>收货人手机号: {{ allData.refund.customer_ship_mobile }}</span>
          </p>
          <div class="logistics-info">
            <p v-if="!refundExpressInfo.data" style="display:block; line-height: 20px;">{{
              refundExpressInfo.message
            }}</p>
            <ul v-else>
              <li v-for="(ship, index) in refundExpressInfo.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="refund_detail.refuse_type_text !== '退款退货' && refundExpressInfo1">
          <h4>新发货商品物流信息：</h4>
          <p class="new_logistics">
            <span>快递公司: {{ refundExpressInfo.name }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ refundExpressInfo.courier_num }}</span>
          </p>
          <div class="logistics-info">
            <p v-if="!refundExpressInfo.data" style="display:block; line-height: 20px;">{{
              refundExpressInfo.message
            }}</p>
            <ul v-else>
              <li v-for="(ship, index) in refundExpressInfo.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="操作" width="500px" :visible.sync="goodsRefundshow">
      <el-form :model="refundInfo">
        <el-form-item label="售后单号" label-width="120px">
          {{ refundInfo.sn }}
        </el-form-item>
        <el-form-item label="关联订单" label-width="120px">
          {{ refundInfo.trade_sn }}
        </el-form-item>
        <el-form-item label="申请时间" label-width="120px">
          {{ refundInfo.create_time | unixToDate }}
        </el-form-item>
        <el-form-item label="售后状态" label-width="120px">
          {{ refundInfo.refund_status_text }}
        </el-form-item>
        <el-form-item label="服务类型" label-width="120px">
          {{ refundInfo.refuse_type_text }}
        </el-form-item>
        <el-form-item label="申请原因" label-width="120px">
          {{ refundInfo.refund_reason }}
        </el-form-item>
        <el-form-item label="问题描述" label-width="120px">
          {{ refundInfo.customer_remark }}
        </el-form-item>
        <el-form-item label="图片凭证" label-width="120px">
          <div style="flex-wrap: wrap; width: 100%; align-content: flex-start;">
            <el-image v-for="(item, index) in refund_img" :key="index" style="width: 100px; height: 100px" :src="item"
              :preview-src-list="refund_img">
            </el-image>
          </div>
        </el-form-item>
        <el-form-item label="退货方式" label-width="120px">
          {{ refundInfo.refund_ship_type ? '快递至第三方卖家' : '上门取件' }}
        </el-form-item>
        <!-- 站点退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shopInfo.shop_type === 6">
          {{ refundInfo.refund_shop_price }}
        </el-form-item>
        <!-- 供应商退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shopInfo.shop_type === 2">
          {{ refundInfo.refund_supplier_price }}
        </el-form-item>
        <!-- 企业-退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shopInfo.shop_type === 3">
          {{ refundInfo.refund_enterprise_price }}
        </el-form-item>
        <el-form-item label="审核" label-width="120px">
          <el-radio v-model="refund_type" label="1">通过</el-radio>
          <el-radio v-model="refund_type" label="2">拒绝</el-radio>
        </el-form-item>
        <el-form-item label="拒绝原因" label-width="120px" v-if="refund_type === '2'">
          <el-input type="textarea" v-model="refund_reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsRefundshow = false">取 消</el-button>
        <el-button type="primary" @click="handleRefund1(refundInfo.sn)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 发货 -->
    <el-dialog title="订单发货" :visible.sync="fhBoxShow" width="70%" :before-close="handleClose">
      <div class="fhBox">
        <div class="row no-gutters">
          <div class="col"></div>
          <el-button type="primary" size="small" :disabled="!selectList.length" @click="submitFhing">确认发货</el-button>
        </div>
        <el-table border ref="multipleTable" :data="sku_list" style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }">
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column prop="sku_sn" label="商品编号" width="180px"></el-table-column>
          <el-table-column prop="name" label="商品名称以及规格" min-width="120px"></el-table-column>
          <el-table-column prop="num" label="商品数量" width="80px"></el-table-column>
          <el-table-column label="物流公司" width="180px">
            <template slot-scope="scope">
              <el-select size="mini" v-model="scope.row.logi_id" placeholder="请选择"
                @change="filterLogisticsData(scope.row)">
                <el-option v-for="item in logisticsData" :key="item.logi_id" :label="item.name"
                  :value="item.logi_id"></el-option>
              </el-select>
              <input type="text" style="display: none" v-model="scope.row.logi_name" />
            </template>
          </el-table-column>
          <el-table-column label="物流操作" width="200px">
            <template slot-scope="scope">
              <ul class="wlcz" style="list-style: none;">
                <li v-for="(item, index) in scope.row.delivery_list" :key="index">
                  <el-input size="mini" placeholder="请输入物流单号" v-model="item.delivery_no"></el-input>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as API_refund from '@/api/refund'
import * as API_Order from '@/api/order'
import * as API_logistics from '@/api/expressCompany';
import * as Foundation from '@/../ui-utils/Foundation';
import { param } from '@/utils';
import { getCnpcShopIds } from "@/api/account"
export default {
  name: 'orderDetail',
  computed: {
    ...mapGetters(['shopInfo'])
  },
  data () {
    return {
      rowKeysList: [],
      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      orderDetail: {},
      allData: {},
      productList: [],
      /*  订单编号 */
      sn: '',
      route: '',
      activeName: '1',
      /** 物流信息 */
      expressInfo: [],
      refund_detail: '',
      self_type: '1',
      refundExpressInfo: null,
      refundExpressInfo1: null,
      imgList: [],
      state: 0,
      refund_type: '1',
      refund_reason: '',
      goodsRefundshow: false,
      refundInfo: {},
      refundMoney: 0,
      refund_img: [],
      // 发货
      fhBoxShow: false,
      sku_list: [],
      selectList: [],
      /** 物流信息 */
      logisticsData: [],
      refund_sn: '',
      sourceData: '',
      ispetroChina: false,//是否是中石油商城
    };
  },
  filters: {
    paymentTypeFilter (val) {
      return val === 'ONLINE' ? '在线支付' : '线下支付';
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.sn = to.params.sn;
    this.GET_OrderDetail();
    next();
  },
  async mounted () {
    this.sn = this.$route.params.sn;
    this.self_type = this.$route.query.self_type
    this.GET_OrderDetail();
    this.getLogisticsCompanies()
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
  },
  activated () {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail();
  },
  methods: {
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then((res) => {
        this.logisticsData = res;
      });
    },
    handleClose () {
      this.fhBoxShow = false;
    },
    /** 打开审核弹窗 */
    examineRefund (sn) {
      API_refund.getRefundDetails(sn).then((response) => {
        this.goodsRefundshow = true
        this.refundInfo = response.refund
        this.refundMoney = response.refund.refund_shop_price
        if (this.refundInfo.refund_img) {
          this.refund_img = this.refundInfo.refund_img.split(',')
        }
      })
    },
    /** 审核操作 */
    handleRefund1 (sn) {
      const _params = {
        agree: this.refund_type,
        refund_shop_price: this.refundMoney,
        sn: sn,
        reason: this.refund_reason
      }
      console.log(_params)
      API_refund.refundAuth(sn, _params).then(() => {
        this.goodsRefundshow = false;
        this.GET_OrderDetail();
      });
    },
    /** 确认收货操作 */
    handleWareHousing (sn) {
      this.$confirm(`请确认是否收到货物，否则会钱财两空`, '操作', {
        type: 'info',
      }).then(() => {
        API_refund.wareHousing(sn).then(() => {
          this.GET_OrderDetail();
        });
      });
    },
    /** 发货操作 */
    deliverGoods (order_sn, sn) {
      this.refund_sn = sn
      this.fhBoxShow = true
      API_Order.getOrderItemsList(order_sn).then((response) => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach((item) => {
            item.delivery_list = [{}];
          })
          this.sku_list = sku_list;
        }
      });
    },

    /** 确认发货 */
    submitFhing () {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        let ship_num = 0;
        if (!item.logi_id) {
          this.$message.error('请选择物流公司');
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error('请输入物流单号');
              return false;
            }
          }
        }
      }
      this.$confirm('确认发货?', '提示', { type: 'warning' }).then(() => {
        let fhInfo = {
          sn: this.refund_sn,
        };
        this.selectList.forEach((item, index) => {
          item.delivery_list.forEach((item1, index1) => {
            fhInfo['seller_logi_id'] = item.logi_id
            fhInfo['seller_logi_name'] = item.logi_name
            fhInfo['seller_ship_no'] = item1.delivery_no
          });
        });

        API_refund.sellerShip(this.refund_sn, fhInfo).then(() => {
          this.$message('发货成功')
          this.goodsRefundshow = false;
          this.fhBoxShow = false;
          this.GET_OrderDetail();
        });
      });
    },

    handleSelectionChange (list) {
      this.selectList = list;
    },

    filterLogisticsData (row) {
      this.logisticsData.forEach((res) => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    fService_status (status) {
      switch (status) {
        case 'aallow_refuse_ship':
          return '允许拒绝发货';
        case 'allow_admin_refund':
          return '待平台审核';
        case 'allow_apply':
          return '允许申请售后';
        case 'allow_buyer_ship':
          return '待寄回';
        case 'allow_cancel':
          return '用户取消售后';
        case 'allow_seller_approval':
          return '退款失败';
        case 'allow_seller_refund':
          return '供应商审核';
        case 'allow_seller_ship':
          return '供应商发货';
        case 'allow_stock_in':
          return '待入库';
        case 'complete':
          return '完成'
      }
    },
    trade_status (status) {
      switch (status) {
        case 'NEW':
          return '新订单'
        case 'CONFIRM':
          return '待付款'
        case 'PAID_OFF':
          return '待发货'
        case 'SHIPPED':
          return '已发货'
        case 'ROG':
          return '已收货'
        case 'COMPLETED':
          return '已完成'
        case 'COMPLETE':
          return '已完成'
      }
    },
    /** 获取订单详情信息 */
    GET_OrderDetail () {
      this.loading = true;
      API_refund.getRefundDetails(this.sn).then(res => {
        this.loading = false;
        this.orderDetail = res.trade_do;
        this.allData = res;

        if (!this.orderDetail) return this.$message.error('数据加载失败！');

        let order_total_price = 0
        res.refund_goods.forEach((item) => {
          order_total_price += item.shop_supplier_price * item.ship_num
        });

        this.orderDetail['order_total_price'] = order_total_price
        this.productList = res.refund_goods;
        this.state = res.state

        if (res.delivery_json) {
          this.expressInfo = JSON.parse(res.delivery_json)
        } else {
          this.activeName = '2'
        }

        this.refund_detail = res.refund;
        this.sourceData = res.source;

        if (this.refund_detail.refund_img) {
          this.imgList = this.refund_detail.refund_img.split(',')
        }

        if (this.expressInfo) {
          if (!this.expressInfo[0].logiId) {
            this.expressInfo[0].logiId = 12
          }
        }
        let params = {}
        params['order_sn'] = this.refund_detail.order_sn
        params['sku_id'] = this.productList[0].sku_id
        params['ext_goods_id'] = this.productList[0].ext_id
        params['is_combo'] = 0
        if (this.expressInfo) {
          API_Order.getExpressListBySkuIdNew(params).then(res => {
            if (res.code === 1) {
              this.expressInfo[0]['data'] = res.data
              this.expressInfo[0]['data']['orderTrack'].reverse()
              this.expressInfo[0]['code'] = 1
            } else {
              this.expressInfo[0]['data'] = res.data
              this.expressInfo[0]['message'] = res.message
            }
            this.$forceUpdate()
          }).then(() => {
            // 供应商物流信息
            if (this.refund_detail.seller_logi_id && this.refund_detail.seller_ship_no) {
              API_Order.getLogisticsInfo({
                id: this.refund_detail.seller_logi_id,
                num: this.refund_detail.seller_ship_no,
                mobile: this.orderDetail.consignee_mobile
              }).then(res => {
                this.refundExpressInfo1 = res
              })
            }
            // 买家回寄物流信息
            if (this.refund_detail.customer_logi_id && this.refund_detail.customer_ship_no) {
              API_Order.getLogisticsInfo({
                id: this.refund_detail.customer_logi_id,
                num: this.refund_detail.customer_ship_no,
                mobile: this.orderDetail.consignee_mobile
              }).then(res => {
                this.refundExpressInfo = res
              })
            }
          })
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item {
  margin-bottom: 0;

  .el-form-item__label {
    line-height: 25px;
  }

  .el-form-item__content {
    line-height: 25px;
  }

  .el-radio-group {
    padding-top: 6px;
  }
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

// /deep/ .el-table--border {
//   /* border: 1px solid #000000 !important; */
// }
.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}
.new_logistics{
  background-color: #6666; 
  height: 30px; 
  line-height: 30px; 
  padding:0 12px
}
</style>
